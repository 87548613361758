<template>
    <q-submenu base-url="/leads/lead_orders" :title="title" :titlelink="titlelink" :buttons="buttons" :submenu="submenu" class="q-stycky"></q-submenu>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    props: ["hasDemo"],
    name: 'QAgentLeadOrderSubmenu',
    data() {
        return {
            title: "Lead Orders",
            titlelink: []
        }
    },
    computed: {
        buttons: function() {
            var b = []
            if (this.hasPermission("agent:DXLeadOrders")) {
                b.push({
                    label: "Lead Orders",
                    //to: "/leads/dx-lead-orders",
                    icon: "fas fa-arrow-alt-circle-right",
                    children: [{
                        label: "DX Lead Orders",
                        to: "/leads/dx-lead-orders"
                    }]
                })
            }
            if (this.hasPermission("agent:RecruitingLeadOrders")) {
                b[0].children.push({
                    label: "Recruiting Lead Orders",
                    to: "/leads/recruiting-lead-orders",
                    icon: "fas fa-arrow-alt-circle-right",
                })
            }
            if (this.hasPermission("agent:Quility Digital LeadLeadOrders")) {
                b[0].children.push({
                    label: "Quility Digital Lead Orders",
                    to: "/leads/quility-digital-lead-orders",
                    icon: "fas fa-arrow-alt-circle-right",
                })
            }
            if (this.hasPermission("agent:Debt Free LifeLeadOrders")) {
                b[0].children.push({
                    label: "Debt Free Lead Orders",
                    to: "/leads/dfl-lead-orders",
                    icon: "fas fa-arrow-alt-circle-right",
                })
            }
            if (this.hasRole(['AgencyOwner', 'SuperAdmin', 'Exec', 'Staff'])) {
                b[0].children.push({
                    label: "Approve Lead Orders",
                    to: "/leads/approve-digital-lead-orders",
                    icon: "fas fa-arrow-alt-circle-right",
                })
            }
            if (this.hasRole(['SuperAdmin', 'Exec', 'Staff'])) {
                b.push({
                    label: "Grouped By State",
                    to: "/leads/dx-lead-orders-by-state",
                    icon: "fas fa-arrow-alt-circle-right",
                })
                b.push({
                    label: "Lead Allocation",
                    to: "/leads/dx-allocate-leads",
                    icon: "fas fa-arrow-alt-circle-right",
                })
            }
            return b;
        },
        submenu: function() {
            var g = this;
            var submenu = [{
                label: "Help",
                to: "/support"
            }, ];
            return submenu;
        },

    },
    components: {
        QSubmenu
    },
    methods: {

    },
}
</script>
<style>
</style>