<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agent-lead-order-submenu v-if="hasRole(['SuperAdmin', 'Staff', 'Exec'])" has-demo="true" v-on:demo="startDemo()"></q-agent-lead-order-submenu>
        <q-my-leads-submenu v-else></q-my-leads-submenu>
        <v-row class="mx-5">
            <v-col cols=12 class="text-center">
                <q-approve-digital-lead-order-data-table :agent="user.Agent"></q-approve-digital-lead-order-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
import QApproveDigitalLeadOrderDataTable from '@/components/datatables/LeadOrders/QApproveDigitalLeadOrderDataTable.vue';
import QAgentLeadOrderSubmenu from '@/components/navigation/Submenus/QAgentLeadOrderSubmenu.vue';
import QMyLeadsSubmenu from '@/components/navigation/Submenus/QMyLeadsSubmenu.vue';
export default {
    data() {
        return {
            //search: '',
        }
    },
    components: {
        QApproveDigitalLeadOrderDataTable,
        QAgentLeadOrderSubmenu,
        QMyLeadsSubmenu
    },
    methods: {

    },

}
</script>