<template>
    <div>
        <v-card flat id="lead_order_datatable">
            <v-container fluid class="ma-0 pa-0 q_leads_1" :id="entity + '_datatable'">
                <v-row>
                    <v-col cols="12" sm="6" id="q-pending-policy-step-3" class="mb-0 pb-0 px-7 text-left">
                        <p class="q-display-3 pb-0 pt-0 mt-0 mb-0 white--text">Lead Order Sales Summary
                            <v-btn dark icon @click="refreshData">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="12" sm="6" class="pr-8 pl-7 pr-3 mb-0 pb-0 d-flex justify-end">
                        <v-spacer></v-spacer>
                        <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-row class="d-flex justify-start pa-2">
                <v-col cols="3">
                    <v-select v-if="available_filters" label="Lead Type" :items="lead_type_options" v-model="filters.LeadType"></v-select>
                </v-col>
                <v-col cols="3">
                    <q-date-picker label="Start Date" v-model="filters.startDate"></q-date-picker>
                </v-col>
                <v-col cols="3">
                    <q-date-picker label="End Date" v-model="filters.endDate"></q-date-picker>
                </v-col>
                <v-col cols="3">
                    <v-btn @click="refreshData">Apply</v-btn>
                </v-col>
            </v-row>
            <v-row class="ml-5 mb-3">
                <v-btn text small color="q_leads_4" @click="exportResults">
                    <v-icon small left>fas fa-file-export</v-icon> Export Results
                </v-btn>
            </v-row>
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="ID" class="elevation-0 lead-order-table" :footer-props="{itemsPerPageOptions:[50, 100, 200, 300]}" :loading="loading" :options.sync="options">
                <template v-slot:item.Amount="{ item }">
                    {{formatLongCurrency(item.Amount)}}
                </template>
                <template slot="body.append">
                    <tr>
                        <th class="title text-center" v-for="(header,ix) in the_headers" :key="ix">
                            <div class="text-right" v-if="header.value == 'Amount'">
                                {{ formatLongCurrency(sumField('Amount')) }}
                            </div>
                            <div v-else-if="header.value == 'LeadQty'">
                                {{ sumField('LeadQty') }}
                            </div>
                            <div v-else>
                            </div>
                        </th>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
const { Parser } = require('json2csv');
import QFilters from '@/components/utils/QFilters.vue'
import moment from 'moment'
import QDatePicker from '@/components/utils/QDatePicker.vue'

export default {
    name: "QLeadSalesReceiptSummaryDataTable",
    props: [],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'lead_order',
            //use this to "preset" a filter
            filters: {
                "LeadType": "-All-",
                'startDate': moment().startOf('month').format('YYYY-MM-DD'),
                "endDate": moment().endOf('month').format('YYYY-MM-DD')
            }, //these are the filters the user has set or you can preset them
            options: {
                'sortBy': ['OrderDate'], //used to set the default sorting column
                'sortDesc': [false],
            },
            the_data: [], //this will hole the data fromt he server now that we aren't using vuex anymore.
            search: null,
            selected: [],
            data_loading: false,
            data_available_filters: null
        }
    },
    mounted: function() {
        //this.$refs.filters.show = true
        this.refreshData()
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            return ['LeadType']; //this must sync up with availableFilters in the Model in Laravel.
        },
        total_items: function() {
            return this.the_data.length;
        },
        the_headers: function() {
            var r = [{
                text: 'Lead Type',
                value: 'LeadType',
                align: "left"
            }, {
                text: 'Lead Level',
                value: 'LeadLevel',
                align: "left"
            }, {
                text: 'Leads',
                value: 'LeadQty',
                align: "center"
            }, {
                text: 'Amount',
                value: 'Amount',
                align: "right"
            }]
            return r;
        },

        available_filters: function() {
            return this.data_available_filters;
        },
        export_fields: function() {
            return [
                'LeadType',
                'LeadLevel',
                'LeadQty',
                'Amount'
            ]
        },
        lead_type_options: function() {
            var r = this.available_filters.LeadType;
            r.push({
                'text': '-All-',
                'value': '-All-'
            })
            return r.sort();
        }
    },
    methods: {
        loadData: debounce(function(opt) {
            this.data_loading = true
            var g = this;
            QuilityAPI.getLeadOrderReceiptSummaryData(this.filters, opt).then(function(json) {
                g.data_loading = false
                g.$set(g, 'the_data', json.data);
                if (json.meta.filters) {
                    g.data_available_filters = json.meta.filters
                }
            }).catch(function(error) {
                g.showError("There was a problem getting the data.", error);
                g.data_loading = false
            });
        }, 200),
        refreshData: function(search) {
            var extra = {
                needAvailableFilters: this.available_filters == null ? true : false
            }
            var opt = {
                ...this.options,
                ...extra
            }
            this.loadData(opt);
        },
        updateCurrentItems: function(items) {
            this.currentItems = items;
        },
        alignClass: function(header) {
            if (header.align == "center") {
                return "text-middle text-center"
            }
            if (header.align == "left") {
                return "text-start text-center"
            }
            if (header.align == "right") {
                return "text-end"
            }
        },
        exportResults: function() {
            var fields = this.export_fields;
            var opts = { fields };
            try {
                var parser = new Parser(opts);
                var csv = parser.parse(this.the_data);
                var fileURL = window.URL.createObjectURL(new Blob([csv]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                var fileName = this.slugify('LeadReceiptSummaryExport_' + this.filters.startDate + "_to_" + this.filters.endDate);
                fileLink.setAttribute('download', fileName + ".csv");
                document.body.appendChild(fileLink);
                fileLink.click();
                this.logActivity("Lead Receipt Export", { "leads": this.title })
            } catch (err) {
                console.error(err);
            }
        },
        sumField(key) {
            if (typeof this.all_data.reduce == 'undefined') {
                return null
            }
            return this.all_data.reduce((a, b) => a + (Number(b[key]) || 0), 0)
        }
    },
    watch: {
        'filters': {
            deep: true,
            handler(newV, oldV) {
                this.options.page = 1
            }
        },
        'filters.LeadType': function(n) {
            var g = this;
            this.$nextTick(g.refreshData)
        },
    },
    components: {
        QFilters,
        QDatePicker
    }
}
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

.grey_row {
    background-color: #f7f7f7;
}

.lead-order-table {
    font-size: 12px;
}

.lead-order-line-table {
    background-color: #f7f7f7;
}

.lead-order-line-table table td {
    font-size: .9em;
    width: 10%;
}
</style>
<style>
.lead-divider {
    margin: 0 6px;
}
</style>