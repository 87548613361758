<!--
The agent form for updating agents. Basically edits the dbo.Agent table, updates Opt info and pulls data from the onboarding portal.
//-->
<template>
    <div id="editformtop" class="pa-4">
        <v-progress-linear indeterminate v-if="loading || loading_balance"></v-progress-linear>
        <v-form v-if="!loading && !loading_balance" lazy-validation v-model="valid2" ref="form2" :disabled="saving">
            <v-row wrap v-if="account_problem && !loading_balance">
                <v-col cols="12">
                    <h4>Credit Card Issue</h4>
                    <p>There is an issue with your saved payment profile. Please add a valid payment profile before ordering leads. You can also visit your <router-link to="/user/account_balance">payment profile page</router-link> for more information. </p>
                    <q-save-payment-profile :agent-code="agentCode" :no-dialog="true" v-on:newcard="reset"></q-save-payment-profile>
                </v-col>
            </v-row>
            <v-row wrap v-else>
                <!--begin-->
                <v-col cols="12">
                    <h3>{{leadType}} Lead Order Form</h3>
                    <p v-if="leadType == 'Recruiting'">0-14 days old. {{formatLongCurrency(lead_prices[leadType].Unlicensed[contract_level])}} unlicensed or {{formatLongCurrency(lead_prices[leadType].Licensed[contract_level])}} for licensed at the {{contract_level}} Contract Level. A nationwide option is available. Select your preferred states first then add a line itme for Nationwide.</p>
                    <p v-else>Exclusive Life Leads sourced from multiple private digital partnerships {{formatLongCurrency(lead_prices[leadType][contract_level])}} each at the {{contract_level}} Contract Level. Standing state-wide orders are accepted and processed in HQ.</p>
                    <p v-if="leadType == 'Recruiting'">
                        <v-icon left large>fas fa-check</v-icon>Valid credit card on file. Current balance is {{formatCurrency(balance)}}
                    </p>
                    <v-checkbox v-else v-model="creditcard_on_file" label="I have a credit card on file in Opt!"></v-checkbox>
                    <v-alert type="warning" v-if="!creditcard_on_file">Prior to submitting this order, please sign into Opt! using the single sign on button in the top left and confirm that you have credit card information saved in Opt!. All requests that do not have a credit card saved in Opt! will be denied.</v-alert>
                </v-col>
                <v-col cols="12" class="text-left" v-if="creditcard_on_file">
                    <v-select :disabled="id!='new'" label="Order Type" v-model="order.OrderType" :items="['Standing Order', 'One-time Order']" :rules="[rules.required]" :messages="order_type_description"></v-select>
                    <div v-if="hasRole(['SuperAdmin', 'Staff'])">
                        <v-select label="Order Status" v-model="order.OrderStatus" :items="status_items"></v-select>
                        <v-text-field label="Order Date" v-model="order.OrderDate"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" v-if="creditcard_on_file">
                    <v-row dense v-for="(line, ix) in order.LeadOrderLines" :key="ix" :class="line.OrderLineStatus">
                        <v-col cols="6">
                            <v-select :disabled="line.ID != 'New'" label="State*" :items="states(line.State)" v-model="line.State" :rules="[form_rules.required]"></v-select>
                        </v-col>
                        <v-col cols="5" v-if="line.State != 'Nationwide'">
                            <v-text-field :disabled="(['Open', 'Needs Approval']).indexOf(line.OrderLineStatus) == -1" label="Quantity*" type="number" v-model.number="line.Qty" :rules="getQtyRules(line)"></v-text-field>
                        </v-col>
                        <v-col cols="5" v-else>
                            <v-text-field :disabled="true" label="Quantity*" type="number" v-model="line.Qty" messages="Only used if fulfillment in preferred states cannot be met."></v-text-field>
                        </v-col>
                        <v-col cols="1" class="pt-5">
                            <v-btn :disabled="(['Open', 'Needs Approval']).indexOf(line.OrderLineStatus) == -1" color="red" icon @click="removeLine(ix)">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <div class="text-center">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-on="on" icon @click="addNewLine">
                                    <v-icon>fas fa-plus-circle</v-icon>
                                </v-btn>
                            </template>
                            <span>Add a new state</span>
                        </v-tooltip>
                    </div>
                </v-col>
                <v-col cols="12" v-if="creditcard_on_file">
                    <div class="scroll elevation-0" style="height: 150px; text-align:left; border:#ccc 1px solid;">
                        <page slug="dx-lead-order-agreement" :no-template="true" class="disclaimer-text"></page>
                    </div>
                    <v-alert type="error" v-if="error">
                        {{ error }}
                    </v-alert>
                    <v-alert type="warning" v-if="warning">
                        {{ warning }}
                    </v-alert>
                    <v-divider class="ma-6"></v-divider>
                    <h4>Total Leads Requested <span v-if="order.OrderType == 'Standing Order'">per Week</span>: <span class="green--text">{{total_leads_requested}}</span>
                        <v-tooltip top v-if="total_leads_requested > 50">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="yellow" v-bind="attrs" v-on="on">fas fa-exclamation-triangle</v-icon>
                            </template>
                            <span>Max # Leads per order is 50!</span>
                        </v-tooltip>
                        <br>
                        <span v-if="leadType == 'Recruiting'">
                            <span v-if="order.OrderType == 'Standing Order'">Max cost will be <span class="green--text">{{formatLongCurrency(lead_prices[leadType].Unlicensed[contract_level] * total_leads_requested)}} to {{formatLongCurrency(lead_prices[leadType].Licensed[contract_level] * total_leads_requested)}}</span> per week. </span>
                            <span v-else>Total cost will be <span class="green--text">{{formatLongCurrency(lead_prices[leadType].Unlicensed[contract_level] * total_leads_requested)}} to {{formatLongCurrency(lead_prices[leadType].Licensed[contract_level] * total_leads_requested)}}</span>. </span>
                        </span>
                        <span v-else>
                            <span v-if="order.OrderType == 'Standing Order'">Max cost will be <span class="green--text">{{formatLongCurrency(lead_prices[leadType][contract_level] * total_leads_requested)}}</span> per week. Your credit card will be charged to top off your balance when you place or modify this order. </span>
                            <span v-else>Total cost will be <span class="green--text">{{formatLongCurrency(lead_prices[leadType][contract_level] * total_leads_requested)}}</span>. </span>
                        </span>
                    </h4>
                    <p>
                        <v-checkbox v-model=" accept_agreement" :label="'I agree to the \'Lead Order Agreement\' above and will continue to maintain a saved credit card on file in HQ for the duration of this order so that payment can be made when leads are allocated. I understand that my card will be pre-charged to cover the max amount of leads ordered.'">
                        </v-checkbox>
                    </p>
                    <v-btn :disabled="saving || !accept_agreement" large color="primary" @click="saveOrder">
                        <span v-if="id == 'new'">Place {{ order.OrderType }} Order</span>
                        <span v-else>Save Changes</span>
                        <v-progress-circular indeterminate size=20 v-if="saving"></v-progress-circular>
                    </v-btn>
                    <v-btn class="ml-3" @click="$emit('cancel')">Cancel</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import StateOptions from '@/store/StateOptions'
import StateAbbreviations from '@/store/StateAbbreviations'
import rules from '@/components/utils/ValidationRules'
import Page from '@/CMS/views/index.vue'
import LeadPrices from '@/store/lead_pricing'
import moment from 'moment'
import QSavePaymentProfile from '@/components/authorizenet/QSavePaymentProfile.vue';

export default {
    props: ['agentCode', 'id', 'leadType', 'allowNationWide'],
    data() {
        return {
            order: {},
            saving: false, //for progress bars
            warning: null,
            error: null,
            valid2: true, // whether the form is valid or not.
            state_abbreviations: StateAbbreviations,
            rules: rules, //validation rules imported.
            loading: false, //progress indicator,
            creditcard_on_file: false, // change to true when chaging for DX lead orders. 
            accept_agreement: false,
            contract_level: null,
            min_five_rule: v => Number(v) >= 5 || 'Minimum order per state is 5 Leads.',
            balance: null,
            balance_data: null,
            loading_balance: false,
            account_problem: false, //change to true when we want to start charging for lead orders. 
            account_problem_text: ''
        }
    },
    mounted: function() {
        this.reset();
    },
    computed: {
        'product_type': function() {
            if (this.leadType == 'Recruiting') {
                return "REC"
            }
            return "TL";
        },
        'total_leads_requested': function() {
            var c = 0;
            if (typeof this.order.LeadOrderLines == 'undefined') {
                return 0
            }
            var g = this
            this.order.LeadOrderLines.forEach(function(l, ix) {
                if ((l.OrderLineStatus == 'Open' || l.OrderLineStatus == 'Needs Approval') && l.State != "Nationwide") {
                    c = c + l.Qty
                } else if (l.State == "Nationwide") {
                    g.order.LeadOrderLines[ix].Qty = 0
                }
            })
            return c
        },
        order_type_description: function() {
            if (this.order.OrderType == 'Standing Order') return ["This will create a new standing order for the same states and quantities every week."]
            if (this.order.OrderType == 'One-time Order') return ["This will create a single order that will get fulfilled once."]
        },
        order_states: function() {
            return this.order.LeadOrderLines.map(a => a.State);
        },
        status_items: function() {
            return ['Open', 'Draft', 'Complete', 'Canceled', 'New', 'Paused by Agent', 'Paused by Admin', 'In Review', 'Declined'];
        },
        lead_prices: function() {
            return LeadPrices;
        },
        max_cost_low: function() {
            return this.lead_prices[this.leadType].Unlicensed[this.contract_level] * this.total_leads_requested
        },
        amount_to_charge: function() {
            if (this.leadType == 'Recruiting') {
                return this.lead_prices[this.leadType].Licensed[this.contract_level] * this.total_leads_requested
            }
            return this.lead_prices[this.leadType][this.contract_level] * this.total_leads_requested
        }
    },
    methods: {
        getStateAbbreviation(state) {
            if (this.states.indexOf(state) > -1) {
                return state
            }
            if (typeof this.state_abbreviations[state] != 'undefined') {
                return this.state_abbreviations[state];
            }
            return null
        },
        reset: function() {
            this.saving = false;
            this.warning = null
            this.accept_agreement = false
            this.balance_data = null
            this.balance = null
            if (this.leadType == 'Recruiting'){
                this.account_problem = true;
                this.creditcard_on_file = true
            }else{
                this.account_problem = false; //change to true when we want to charge for DX leads in HQ. 
            }
            this.account_problem_text = ''
            if (this.id == "new") {
                this.order = {
                    OrderType: "Standing Order",
                    AgentCode: this.agentCode,
                    Qty: 0,
                    LeadType: this.leadType,
                    OrderDate: moment().format('YYYY-MM-DD hh:mm:ss'),
                    LeadOrderLines: [{
                        "ID": "New",
                        "LeadType": this.leadType,
                        "State": this.user.Agent.State,
                        "Qty": 5,
                        "ProductType": this.product_type,
                        "County": "STATEWIDE",
                        //Recruiting Lead Orders Reuire AO and HomeOffice approval. 
                        "OrderLineStatus": this.leadType == 'Recruiting' ? "Needs Approval" : "Open",
                    }]
                }
                if (this.hasRole(['Staff', 'SuperAdmin', 'Exec'])) {
                    this.order.OrderStatus = "New"
                }
            } else {
                this.creditcard_on_file = true
            }
            this.getAgentContractLevel(this.agentCode)
            if (this.leadType == 'Recruiting'){
                this.checkForPaymentProfile(this.agentCode); //uncomment when we want to start charging for DX Lead Orders in HQ. 
            }
        },
        addNewLine: function() {
            this.order.LeadOrderLines.push({
                "ID": "New",
                "LeadType": this.leadType,
                "State": null,
                "Qty": 5,
                "ProductType": this.product_type,
                "County": "STATEWIDE",
                //Recruiting Lead Orders Reuire AO and HomeOffice approval. 
                "OrderLineStatus": this.leadType == 'Recruiting' ? "Needs Approval" : "Open",
            })
        },
        removeLine(ix) {
            if (this.order.LeadOrderLines[ix].ID == "New") {
                this.order.LeadOrderLines.splice(ix, 1)
            } else {
                this.order.LeadOrderLines[ix].OrderLineStatus = "Canceled";
            }
        },
        saveOrder: function() {
            var g = this
            this.saving = true
            if (!this.$refs['form2'].validate()) {
                this.warning = "Please correct invalid fields."
                setTimeout(function() { g.warning = null }, 6000)
                this.saving = false;
                return false;
            }
            //do not allow more than 50 leads per order. 
            if (!this.hasRole(['Staff', 'SuperAdmin', 'Exec'])) {
                if (this.total_leads_requested > 50) {
                    this.warning = "The max # leads per order is 50 leads. Please reduce the number of leads requested."
                    this.saving = false;
                    return false
                }
            }
            if (this.id == "new") {
                var the_call = QuilityAPI.placeLeadOrder(this.order.OrderType, this.order.LeadType, this.agentCode, this.order);
            } else {
                the_call = QuilityAPI.updateLeadOrder(this.id, this.order);
            }
            the_call.then(function(json) {
                if (json.success) {
                    g.saving = false
                    if (g.id == "new") {
                        g.showSuccess("Success! Your order # is " + json.data.OrderNumber + ". <br>It will need to be approved by the Home Office<br>before leads will be allocated.");
                    } else {
                        g.showSuccess("Your changes have been saved!");
                    }
                    g.$emit("order_saved");
                } else {
                    g.saving = false
                    g.showError("Oops! There was a problem placing your order.", json.msg);
                }
            }).catch(function(error) {
                g.showError("Oops! There was a problem placing your order.", error);
            })
        },
        goToTop: function() {
            this.$vuetify.goTo('#editformtop', {
                duration: 500,
                easing: 'easeOutCubic',
            })
        },
        states: function(s) {
            var states = [...StateOptions]
            states.sort();
            if (this.allowNationWide === true) {
                states.push("Nationwide");
            }
            if (s != null) {
                return states
            }
            var g = this
            //remove any states already in the list. 
            var array1 = states.filter(function(val) {
                return g.order_states.indexOf(val) == -1;
            });
            return array1
        },
        getQtyRules: function(line) {
            if (line.OrderLineStatus == 'Canceled' || line.OrderLineStatus == 'Closed' || line.OrderLineStatus == 'Complete') {
                return []
            }
            if (this.hasRole(['Staff', 'SuperAdmin', 'Exec'])) {
                //no limit for staff editing.
                return [this.form_rules.int, this.form_rules.required]
            }
            if (line.ID == 'New') {
                //new lines limit to min of 5
                return [this.form_rules.int, this.form_rules.required, this.min_five_rule]
            } else {
                //existing lines - no limit because there may be existing orders.
                return [this.form_rules.int, this.form_rules.required]
            }
        },
        getAgentContractLevel: function(agentcode) {
            var g = this
            g.loading = true
            QuilityAPI.getAgentStateless(agentcode).then(function(json) {
                g.loading = false
                g.$set(g, 'contract_level', json.ContractLevel)
            }).catch(function(err) {
                g.loading = false
            })
        },
        checkForPaymentProfile: function(agent_code) {
            var g = this
            g.loading_balance = true
            QuilityAPI.getAgentAccountBalance(agent_code).then(function(json) {
                g.loading_balance = false;
                if (typeof json.msg != "undefined") {
                    g.account_problem = true;
                    g.account_problem_text = json.msg
                }
                if (typeof json.response != "undefined" && typeof json.response.body.message != "undefined") {
                    g.account_problem = true;
                    g.account_problem_text = json.response.body.message
                }
                if (json.data) {
                    g.balance_data = json.data
                    g.balance = json.data.Balance;
                    g.account_problem = false;
                    g.account_problem_text = ""
                }
            }).catch(function(err) {
                console.log(err)
                g.showError(err)
            })
        }
    },
    watch: {
        'agentCode': function(newV) {
            //console.log(newV)
            //this.$nextTick(this.reset)
        },
    },
    components: {
        Page,
        QSavePaymentProfile
    }
}
</script>
<style>
.disclaimer-text.cms .full-width-text p {
    font-size: .7em;
}

.row.Canceled .col .v-select__selection,
.row.Canceled .col .v-text-field__slot {
    text-decoration: line-through;
}
</style>