export default {
    "IA": {
        "70": 30.85,
        "75": 33.06,
        "80": 35.26,
        "85": 37.46,
        "90": 39.67,
        "95": 41.87,
        "100": 44.08,
        "105": 46.28,
        "110": 48.48,
        "115": 50.69,
        "120": 52.89,
        "140": 61.88,
    },
    "DX": {
        "70": 9.00,
        "75": 9.00,
        "80": 9.00,
        "85": 9.00,
        "90": 9.00,
        "95": 9.00,
        "100": 9.00,
        "105": 9.00,
        "110": 9.00,
        "115": 9.00,
        "120": 9.00,
        "140": 9.00,
    },
    "Recruiting": {
        "Licensed": {
            "70": 15.00,
            "75": 15.00,
            "80": 15.00,
            "85": 15.00,
            "90": 15.00,
            "95": 15.00,
            "100": 15.00,
            "105": 15.00,
            "110": 15.00,
            "115": 15.00,
            "120": 15.00,
            "140": 15.89,
        },
        "Unlicensed": {
            "70": 10.00,
            "75": 10.00,
            "80": 10.00,
            "85": 10.00,
            "90": 10.00,
            "95": 10.00,
            "100": 10.00,
            "105": 10.00,
            "110": 10.00,
            "115": 10.00,
            "120": 10.00,
            "140": 10.00,
        }
    }
}